import { Controller } from "@hotwired/stimulus"

document.addEventListener("turbo:before-cache", () => {
  $('#select-user-pronouns').each(function() {
    const el = $(this);
    const selected = el.val();
    el.find('option').prop('selected', false);
    if (selected) {
      selected.forEach(value => {
        el.find(`option[value='${value}']`).prop('selected', true);
      });
    }
  });
});

export default class extends Controller {
  connect() {
    const $select = $('#select-user-pronouns');

    if ($select.data('select2') === undefined && $select.next().hasClass('select2-container')) {
      $select.next().remove();
    }

    $select.select2({
      width: "100%",
      closeOnSelect: false,
      maximumSelectionLength: 4
    });

    $select.on('select2:select', function(e) {
      const id = e.params.data.id;
      const option = $(e.target).children(`[value='${id}']`);
      option.detach();
      $(e.target).append(option).trigger('change');
    });
  }
}
